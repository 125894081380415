import React from 'react'
import style from './Post.module.css'
import ProfilePicturePreview from '../profile/ProfilePicturePreview'


function Post({images, content, author}) {
    return (
        <div className={style.PostContainer}>
            <div className={style.AuthorBlock}>
                <ProfilePicturePreview
                    pictureURL={author.pictureURL}
                    alt={author.username}
                    size={'48pt'}
                />
                <h3 className={style.PostAuthorUsername}>{author.username}</h3>
            </div>

            {
                images && (
                    <div className={style.PostImages}>
                        {images.map((imageURL, index) => (
                            <img src={imageURL} alt='image'/>
                        ))}
                    </div>
                )
            }
            
            <div className={style.PostContent}>
                <p>
                    {content}
                </p>
            </div>

            <div className={style.ReactionBlock}>
                <div>React</div>
                <div>Comment</div>
                <div>Share</div>
            </div>
        </div>
    )
}

export default Post