import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";

import style from './Authentication.module.css'
import WelcomeText from '../../components/text/WelcomeText';
import { authenticationController } from '../../config/controllers';

function SignUp() {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const onContinue = async (e) => {
        e.preventDefault();
        await authenticationController.signUp(login, password);
    }

    const onContinueWithGoogle = (e) => {
        e.preventDefault();
        authenticationController.signInWithGoogle()
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            navigate("/")
            console.log(user);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
    }

    return (
        <div className={style.AuthContainer}>
            <WelcomeText />
            <form className={style.Form} method='post'>
                <input
                    type="text"
                    name="login"
                    id="login"
                    placeholder='Email address or username'
                    value={login}
                    onChange={(e) => {setLogin(e.target.value)}}
                    className={style.FormInput}
                />
                <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder='••••••••'
                    value={password}
                    onChange={(e) => {setPassword(e.target.value)}}
                    className={style.FormInput}
                />
                <button onClick={onContinue} className={[style.FormInput, style.Continue].join(' ')}>Continue</button>
                <div className={style.Hint}>Already have an account? <Link to="/signin">Login</Link></div>
                <div className={style.Or}>
                    <span>OR</span>
                </div>
                <button onClick={onContinueWithGoogle} className={[style.FormInput, style.ContinueWithGoogle].join(' ')}>Continue with Google</button>
            </form>
        </div>
    )
}

export default SignUp