// src/components/SignOut.js
import React from "react";
import { authenticationController } from "../../config/controllers";


const SignOut = () => {
  const handleSignOut = async () => {
    try {
      authenticationController.signOut()
      console.log("User signed out!");
    } catch (error) {
      console.log(error.message);
    }
  };

  

  return (
    <button onClick={handleSignOut}>
      Sign Out
    </button>
  );
};

export default SignOut;
