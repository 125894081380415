import React from 'react'
import WelcomeText from '../../components/text/WelcomeText'
import { auth } from '../../config/firebase'
import UserMenu from '../../components/navigation/UserMenu'

function Home() {
    return (
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <WelcomeText />
            <p>Platform to apply your knowledge and find ambitious people</p>
            <span style={{ opacity: 0.5 }}>The application is under construction</span>
            { auth.currentUser && <UserMenu/> }
        </div>
    )
}

export default Home