import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './views/home/Home';
import SignIn from './views/authentication/SignIn';
import SignUp from './views/authentication/SignUp';

import './index.css'
import DataTheme from './components/DataTheme';
import Explore from './views/explore/Explore';
import ProjectPage from './views/project/ProjectPage';
import ProfilePage from './views/profile/ProfilePage';
import FeedPage from './views/feed/FeedPage';
import ChatPage from './views/chat/ChatPage';
import DashboardPage from './views/dashboard/DashboardPage';
import ProtectedRoute from './views/authentication/ProtectedRoute';
import { AuthProvider } from './views/authentication/Auth';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          {/* <Route
            path='/dashboard'
            element={<DashboardPage />}
          /> */}
          <Route 
            path='/'
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          {/* <Route 
            path='/explore'
            element={<Explore />}
          /> */}
          <Route 
            path='/signin'
            element={<SignIn />}
          />
          <Route 
            path='/signup'
            element={<SignUp />}
          />
          {/* <Route 
            path='/project/:projectID'
            element={<ProjectPage />}
          />
          <Route
            path='/u/:username'
            element={<ProfilePage />}
          /> */}
          <Route
            path='/feed'
            element={<FeedPage />}
          />
          {/* <Route
            path='/chat'
            element={<ChatPage />}
          /> */}
        </Routes>
      </AuthProvider>
      <DataTheme />
    </BrowserRouter>
  );
}

export default App;
