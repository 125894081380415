import React from 'react'
import Calendar from '../../components/calendar/Calendar'

function DashboardPage() {
  return (
    <div>
        <h3>DashboardPage</h3>
        <div>
            <Calendar />
        </div>
    </div>
  )
}

export default DashboardPage