import React from 'react'
import { auth } from '../../config/firebase'
import SignOut from '../../views/authentication/SignOut'
import style from "./UserMenu.module.css"

function UserMenu() {
  return (
    <div className={style.UserMenu}>
        <span>{auth.currentUser.displayName}</span>
        <SignOut />
    </div>
  )
}

export default UserMenu