import React, { useState } from 'react'
import style from './FeedPage.module.css'
import Post from '../../components/post/Post'

function FeedPage() {
    const [posts, setPosts] = useState([
        {
            author: {
                username: "Astrid McLauren",
                pictureURL: "https://images.unsplash.com/photo-1550703703-c6f229024ba9?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            },
            images: [
                'https://images.unsplash.com/photo-1610812387871-806d3db9f5aa?q=80&w=1940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
            ],
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt, nostrum aliquid! Nemo tempore molestiae adipisci totam explicabo cupiditate sequi eius, beatae non amet temporibus vel! Itaque blanditiis fuga error illum ea earum impedit, ex inventore ipsa dolores architecto, sed placeat eius. At consectetur aperiam nesciunt iure recusandae, excepturi dignissimos! Eligendi."
        }
    ])

    return (
        <div className={style.FeedPageContainer}>
            {posts.map((post, index) => (
                <Post
                    images={post.images}
                    content={post.content}
                    author={post.author}
                />
            ))}
        </div>
    )
}

export default FeedPage