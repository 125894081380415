import AuthenticationModel from "../models/authentication/AuthenticationModel";

export default class AuthenticationController {
    model: AuthenticationModel

    constructor(authModel: AuthenticationModel) {
        this.model = authModel;
    }

    async signInWithEmail(email: string, password: string) {
        console.log("Signing In with Email");
        return this.model.signInWithEmail(email, password);
    }
    async signInWithGoogle() {
        console.log("Signing In with Google");
        return this.model.signInWithGoogle();
    }
    async signUp(email: string, password: string) {
        console.log("Signing Up");
        return this.model.signUp(email, password);
    }
    async signOut() {
        console.log("Signing Out");
        return this.model.signOut();
    }
}