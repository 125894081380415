import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import AuthenticationModel from './AuthenticationModel.ts';
import { auth } from '../../config/firebase.js';
import { GoogleAuthProvider } from 'firebase/auth';

export default class FirebaseAuthenticationModel extends AuthenticationModel {
    async signInWithEmail(email: string, password: string) {
        return signInWithEmailAndPassword(auth, email, password);
    }
    async signInWithGoogle() {
        const provider = new GoogleAuthProvider();
        return signInWithPopup(auth, provider);
    }
    async signUp(email: string, password: string) {
        return createUserWithEmailAndPassword(auth, email, password);
    }
    async signOut() {
        return auth.signOut();
    }
}