import React, { useState } from 'react'
import style from './Profile.module.css'
import ProfilePicturePreview from '../../components/profile/ProfilePicturePreview'
import OpeningBlock from '../../components/project/OpeningBlock'

function ProfilePage() {
    const [profile, setProfile] = useState({
        name: "Astrid Manson",
        description: "In progress to achieve the state of art",
        pictureURL: "https://images.unsplash.com/photo-1550703703-c6f229024ba9?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        projectsCount: 6,
        contributesCount: 2,
        roles: [
            {title: "Acoustic guitar player", skillSet: ["Acoustic guitar", "FL studio"], openingContributors: [{username: "bernard.crook", profilePictureURL: "https://images.unsplash.com/photo-1529111290557-82f6d5c6cf85?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}, {username: "alice.thompson", profilePictureURL: "https://images.unsplash.com/photo-1550703703-c6f229024ba9?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}]},
        ]
    })

    return (
        <div className={style.ProfilePageContainer}>
            <header className={style.HeaderContainer}>
                    <div className={style.HeaderProfileDescription}>
                        <p>
                            {profile.description}
                        </p>
                    </div>
                    <div className={style.HeaderProfilePicture}>
                        <ProfilePicturePreview
                            size={"196pt"}
                            pictureURL={profile.pictureURL}    
                        />
                    </div>
                    <div className={style.HeaderProfileInfo}>
                        <h1 className={style.Name}>{profile.name}</h1>
                        <div className={style.ProfileStatistic}>
                            <div className={style.ProfileStatisticItem}>Projects: <span>{profile.projectsCount}</span></div>
                            <div className={style.ProfileStatisticItem}>Contributes: <span>{profile.contributesCount}</span></div>
                        </div>
                        <ul className={style.SocialMediaList}>
                            <li className={style.SocialMedialItem}>Instagram</li>
                            <li className={style.SocialMedialItem}>Telegram</li>
                            <li className={style.SocialMedialItem}>Youtube</li>
                        </ul>
                    </div>
                </header>
            <main className={style.ProfileMain}>
                <div className={style.RolesGrid}>
                    {profile.roles.map((role) => (
                        <OpeningBlock
                            title={role.title}
                            skillSet={role.skillSet}
                            openingContributors={role.openingContributors}
                        />
                    ))}
                </div>
            </main>
        </div>
    )
}

export default ProfilePage